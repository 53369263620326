import { createStore } from 'vuex'

import fundingModule from './funding'
import mapModule from './map'
import sliderModule from './sliders'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    fundingModule,
    mapModule,
    sliderModule
  }
})
