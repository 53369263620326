export default {
  state: {
    totalRaised: null,
    lastUpdated: null,
  },
  getters: {
    totalRaisedFigureReceived: (state: any) => state.totalRaised !== null && state.totalRaised !== undefined,
    totalRaised: (state: any) => state.totalRaised,
    lastUpdated: (state: any) => state.lastUpdated
  },
  mutations: {
    setTotalRaised(state: any, payload: any) {
      state.totalRaised = payload.totalDonations
      state.lastUpdated = payload.lastUpdated
    }
  },
  actions: {
    async fetchTotalRaised({ commit }: { commit: any }) {
      // const response = await GetMockData()
      const response = (await (await fetch('/api/web/totaldonations')).json())
      commit('setTotalRaised', response)
    }
  }
}


function GetMockData() {
  return new Promise(resolve => {
    setTimeout(() => resolve({ totalDonations: 249520, lastUpdated: new Date() }), 10000)
  })
}