import dayjs, { Dayjs } from 'dayjs'

const EVENT_START_DATE = '2022-11-06'
const EVENT_END_DATE = '2022-11-19'
const HOURLY_INCREMENT = 6

export default {
  state: {
    journey: [],
    initialDataLoaded: false,
  },
  getters: {
    journey: (state: any) => state.journey.flatMap((x: any) => x),
    initialDataLoaded: (state: any) => state.initialDataLoaded
  },
  mutations: {
    addToJourney(state: any, payload: any) {
      state.journey.push(payload)
    },
    initialDataLoaded(state: any) {
      state.initialDataLoaded = true
    }
  },
  actions: {
    async fetchJourney({ commit }: { commit: any }) {

      // Mock
      // const eventStartDate = dayjs('2022-11-01')
      // const eventEndDate = dayjs('2022-11-07')
      // Live
      const eventStartDate = dayjs(EVENT_START_DATE)
      const eventEndDate = dayjs(EVENT_END_DATE)
      
      const now = dayjs()
      let x = eventStartDate

      while (x < now && x < eventEndDate) {
        const from = x
        const to = x.add(HOURLY_INCREMENT, 'hours')

        // If 'to' is greater than 'now' then that must mean
        // we are in the _block_ that contains 'now'. We don't
        // want to cache this as the block may be incomplete,
        // we only want to cache when we know a whole block
        // has been completed and won't change.
        const response = to.isAfter(now)
          ? await GetFromApi(from, to)
          : await GetFromCacheOrApi(from, to)

        if (response.length) {
          commit('addToJourney', response.flatMap((x: any) => JSON.parse(x.recordedJourney)))
        }

        x = to
      }
    },
    initialDataLoaded({ commit }: { commit: any }) {
      commit('initialDataLoaded')
    }
  }
}


const response = [
  { recordedWhen: dayjs('2022-11-06').add(2, 'hours'), recordedJourney: '[{ \"lat\": 1, \"lng\": 1 }, { \"lat\": 2, \"lng\": 2 }]' },
  { recordedWhen: dayjs('2022-11-06').add(14, 'hours'), recordedJourney: '[{ \"lat\": 13, \"lng\": 13 }, { \"lat\": 14, \"lng\": 14 }]' },
  { recordedWhen: dayjs('2022-11-06').add(18, 'hours'), recordedJourney: '[{ \"lat\": 25, \"lng\": 25 }, { \"lat\": 26, \"lng\": 26 }]' },
  { recordedWhen: dayjs('2022-11-06').add(24, 'hours'), recordedJourney: '[{ \"lat\": 52, \"lng\": 0.1 }]' },
]

function GetMockData(fromWhen: Dayjs, toWhen: Dayjs): any {
  return new Promise(resolve => {
    setTimeout(() => resolve(
      response.filter(x => x.recordedWhen.isAfter(fromWhen) && x.recordedWhen.isBefore(toWhen))
    ), Math.random() * 1000)
  })
}

async function GetFromCacheOrApi(from: Dayjs, to: Dayjs) {
  // const cacheKey = from.toISOString()

  // Check local cache
  // const cachedValue = localStorage.getItem(cacheKey)
  // if (cachedValue) return JSON.parse(cachedValue)

  // Nothing cached, fetch from API
  const response = await GetFromApi(from, to)

  // Store return value for future use
  // localStorage.setItem(cacheKey, JSON.stringify(response))

  return response
}

async function GetFromApi(from: Dayjs, to: Dayjs) {
  return (await fetch(`/api/web/journeys?fromWhen=${from.toISOString()}&toWhen=${to.toISOString()}`)).json()
}