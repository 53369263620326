<template>

<main class="w-full h-full">
  <Map />
</main>

<div v-if="showNotStarted" class="absolute top-1/2 transform -translate-y-1/2 z-10 max-w-sm p-4 py-6 bg-white rounded-sm shadow-2xl sm:px-6 sm:left-1/2 sm:-translate-x-1/2">
  <img src="@/assets/lou2-ears.png" class="ml-4 float-right w-20" />
  <div class="space-y-4">
    <h2 class="text-lg text-gray-800 font-bold">Lou hasn’t left yet!</h2>
    <p class="text-sm text-gray-600">
      The journey kicks off on the 6th November.
      <a href="https://www.biglearnerrelay.co.uk/?page_id=1634" target="_blank" class="text-blue-600 underline">Check out the route</a> to find out roughly where and when Lou will be.
      Until then, you can start donating immediately!
    </p>
    <button 
      type="button" 
      @click="$store.dispatch('showDonate', true)" 
      class="px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-md text-white bg-brand-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red"
    >
      Donate
    </button>
  </div>
</div>

<div v-if="showFinished" class="absolute top-1/2 transform -translate-y-1/2 z-10 w-full max-w-sm p-4 bg-white rounded-sm shadow-2xl sm:p-6 left-1/2 -translate-x-1/2">
  <img src="@/assets/lou2-ears.png" class="ml-4 float-right w-20" />
  <div class="space-y-4">
    <h2 class="text-lg text-gray-800 font-bold">The Big Learner Relay 2022 has finished!</h2>
    <p class="text-sm text-gray-600">
      But not to worry, you can continue to donate, and make sure to check out the
      <a href="https://www.biglearnerrelay.co.uk/" target="_blank" class="text-blue-600 underline">Big Learner Relay website</a>
      to find out all the latest news and information.
    </p>
    <div class="flex justify-between">
      <button 
        type="button" 
        @click="$store.dispatch('showDonate', true)" 
        class="px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-md text-white bg-brand-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red"
      >
        Donate
      </button>
      <button 
        type="button" 
        @click="showFinished = false" 
        class="px-4 py-2 border-2 border-gray-300 text-base font-medium rounded-md  text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red"
      >
        View Map
      </button>
    </div>
  </div>
</div>

<Donate />

</template>
<script>

import { ref, watch } from 'vue'
import { useStore } from 'vuex'

import Map from '@/components/Map.vue'
import Donate from '@/components/Donate.vue'

export default {
  components: {
    Map,
    Donate
  },
  setup() {
    const store = useStore()

    store.dispatch('fetchTotalRaised')
    store.dispatch('fetchJourney').then(() => store.dispatch('initialDataLoaded'))

    watch(() => store.getters.initialDataLoaded, hasLoaded => {
      showNotStarted.value = hasLoaded && store.getters.journey.length === 0
    })

    const showNotStarted = ref(false)
    const showFinished = ref(true)

    return {
      showNotStarted,
      showFinished,
    }
  }
}
</script>
