
import { computed, ref } from '@vue/reactivity'
import { GoogleMap, Polyline, CustomControl, Marker, CustomMarker } from 'vue3-google-map'
const ultraLight = [{featureType:"water",elementType:"geometry",stylers:[{color:"#e9e9e9"},{lightness:17}]},{featureType:"landscape",elementType:"geometry",stylers:[{color:"#f5f5f5"},{lightness:20}]},{featureType:"road.highway",elementType:"geometry.fill",stylers:[{color:"#ffffff"},{lightness:17}]},{featureType:"road.highway",elementType:"geometry.stroke",stylers:[{color:"#ffffff"},{lightness:29},{weight:.2}]},{featureType:"road.arterial",elementType:"geometry",stylers:[{color:"#ffffff"},{lightness:18}]},{featureType:"road.local",elementType:"geometry",stylers:[{color:"#ffffff"},{lightness:16}]},{featureType:"poi",elementType:"geometry",stylers:[{color:"#f5f5f5"},{lightness:21}]},{featureType:"poi.park",elementType:"geometry",stylers:[{color:"#dedede"},{lightness:21}]},{elementType:"labels.text.stroke",stylers:[{visibility:"on"},{color:"#ffffff"},{lightness:16}]},{elementType:"labels.text.fill",stylers:[{saturation:36},{color:"#333333"},{lightness:40}]},{elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"geometry",stylers:[{color:"#f2f2f2"},{lightness:19}]},{featureType:"administrative",elementType:"geometry.fill",stylers:[{color:"#fefefe"},{lightness:20}]},{featureType:"administrative",elementType:"geometry.stroke",stylers:[{color:"#fefefe"},{lightness:17},{weight:1.2}]}]
import { useStore } from 'vuex'
import { ArrowCircleLeftIcon } from '@heroicons/vue/solid'
import { watch } from '@vue/runtime-core'
import ThreeDots from './ThreeDots.vue'
import Vue3Autocounter from 'vue3-autocounter'
// @ts-ignore
import Lou from '@/assets/lou2-ears.png'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
// @ts-ignore
import confetti from 'canvas-confetti'

dayjs.extend(RelativeTime)

const defaultMapZoom = 6
const defaultMapCenter = { lat: 54.5317179, lng: -4.4259364 }

const triggerFireworks = () => {
  var duration = 15 * 1000;
  var animationEnd = Date.now() + duration;
  var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0, disableForReducedMotion: true, };

  function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  var interval = setInterval(function() {
    // var timeLeft = animationEnd - Date.now();

    // if (timeLeft <= 0) {
    //   return clearInterval(interval);
    // }

    var particleCount = 50 * (/*timeLeft*/duration / duration);
    // since particles fall down, start a bit higher than random
    //confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
    confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.9), y: Math.random() - 0.2 } }));
    console.log('bang!')
  }, 1000);
}

export default {
  components: {
    GoogleMap,
    Polyline,
    CustomControl,
    ArrowCircleLeftIcon,
    CustomMarker,
    Marker,
    ThreeDots,
    Vue3Autocounter,
  },
  emits: [
    'changeScreen'
  ],
  setup() {
    const store = useStore()
    const gmap: any = ref(null)

    const gmapReady = computed(() => {
      return gmap.value?.ready
    })

    setInterval(() => 
      // Stops map components using Google Maps fonts
      document.querySelector('.gm-style')?.classList.remove('gm-style')
    , 1000)

    const journey = computed(() => store.getters.journey)
    const journeyHasPoints = computed(() => journey.value.length > 0)

    const polyline = computed(() => ({
      path: journey.value.map((x: any) => x),
      strokeColor: '#d0333a', 
      strokeWeight: 5, 
    }))

    const marker = computed(() => {
      const position = journey.value[journey.value.length - 1]
      return {
        position,
        scale: 4,
        animation: google.maps.Animation.DROP,
        icon: {
          url: Lou,
          // Lou 1
          // origin: new google.maps.Point(-30, 0),
          // scaledSize: new google.maps.Size(69, 85),
          // size: new google.maps.Size(99, 85),
          // Lou 2
          // anchor: new google.maps.Point(36, 46),
          // scaledSize: new google.maps.Size(73, 92),
          // Lou 2 Ears
          anchor: new google.maps.Point(30, 42),
          scaledSize: new google.maps.Size(61, 84),
        },
      }
    })

    const initialDataLoaded = computed(() => store.getters.initialDataLoaded)

    const showLou = ref(false)

    watch(() => [initialDataLoaded.value, gmapReady.value], ([isLoaded, mapReady]) => {
      if (!isLoaded || !journeyHasPoints.value || !mapReady) return
      gmap.value.map.panTo(marker.value.position)
      gmap.value.map.setZoom(10)
      // Gives us a chance for map panning/zooming to finish
      setTimeout(() => {
        showLou.value = true
        triggerFireworks()
      }, 1000)
    })

    const totalLastUpdated = computed(() => dayjs(store.getters.lastUpdated).fromNow())

    const polylineRef = ref()
    const mileposts = ref(<any>[])
    watch(() => showLou.value, () => {
      const path = polylineRef.value?.polyline?.getPath()
      if (!path) return

      const totalLength = path.getLength()
      const array = path.getArray()
      const results: { distance: number; coords: any }[] = []
      for (let index = 0; index < totalLength; index = index + 5000) {
        const chosenMarker = array.slice(0, index)
        const distanceInMeters = google.maps.geometry.spherical.computeLength(chosenMarker)
        const distanceInMiles = Math.round(distanceInMeters / 1609)

        results.push({
          distance: distanceInMiles,
          coords: path.getAt(index),
        })
      }

      mileposts.value = results
    })

    return {
      defaultMapZoom,
      defaultMapCenter,
      gmap,
      ultraLight,
      gmapReady,
      journey,
      polyline,
      marker,
      totalLastUpdated,
      showLou,
      journeyHasPoints,
      polylineRef,
      mileposts,
    }
  }
}
