export default {
  state: {
    donateOpen: false
  },
  getters: {
    donateOpen: (state: any) => {
      return state.donateOpen
    }
  },
  mutations: {
    setDonateOpen(state: any, payload: boolean) {
      state.donateOpen = payload
    }
  },
  actions: {
    showDonate({ commit }: { commit: any }, show: boolean) {
      commit('setDonateOpen', show)
    }
  }
};