<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="$store.getters.donateOpen">
    <Dialog as="div" class="fixed inset-0 z-10 overflow-hidden" @close="$store.dispatch('showDonate', false)">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                <div class="px-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      How To Donate
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red" @click="$store.dispatch('showDonate', false)">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-6 relative flex-1 px-4 space-y-6 text-gray-600 text-sm sm:px-6">
                  <!-- Replace with your content -->
                  <p>How much would you like to donate?</p>
                  <div class="flex space-x-3">
                    <button 
                      v-for="amount of donationAmounts"
                      :key="amount"
                      @click="selectedDonationAmount = amount"
                      type="button" 
                      class="inline-flex flex-1 justify-center items-center px-3 py-2 text-sm leading-4 font-medium rounded-md shadow-sm border-2 border-brand-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red"
                      :class="amount === selectedDonationAmount ? 'bg-brand-red text-white' : 'bg-white text-brand-red'"
                    >
                      £{{ amount }}
                    </button>
                  </div>

                  <p v-if="!selectedDonationAmount">We can accept a maximum donation of £20. If you would like to give more, please donate multiple times.</p>

                  <div v-if="selectedDonationAmount" class="py-4">
                    <p>Simply text:</p>

                    <p class="text-3xl text-gray-800 font-semibold mt-2">
                      {{ donateKeyword }}<span class="text-black font-black">{{ selectedDonationAmount }}</span>
                      <span class="text-3xl text-gray-500 font-normal mx-3">to</span>
                      <span class="font-semibold">{{ donateToSmsNumber }}</span>
                    </p>

                    <a 
                      :href="`sms:${donateToSmsNumber};?&body=${donateKeyword}${selectedDonationAmount}`"
                      class="inline-flex items-bottom mt-4 px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-brand-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red"
                    >
                      Open in SMS
                      <ExternalLinkIcon class="w-5 h-5 ml-2" />
                    </a>
                  </div>
                  <div v-if="selectedDonationAmount" class="text-xs text-gray-400 space-y-1">
                    <p class="font-medium">Terms and Conditions</p>
                    <ul class="space-y-1 list-disc pl-6">
                      <li>Our text giving service provider, Donr, charges us a fee equivalent to 5% of each donation.</li>
                      <li>100% of any Gift Aid given on your donation will be processed and received by our charity.</li>
                      <li>When you make a text donation, this will either be deducted from your mobile phone credit (Pay as You Go) or added to your mobile phone bill.</li>
                      <li>You may be charged a standard rate message for your text message (refer to your network operator for further information).</li>
                      <li>You must ask the bill payer’s permission before making a donation. If you are under 16 years old, you must have your parent or guardian’s permission to send a text donation to us.</li>
                      <li>You should receive a confirmation of your donation by text from Donr within an hour of sending this text.</li>
                      <li>If at any time you encounter any problems when making a text donation, you can contact the Donr Helpline by calling 0333 4444 111.</li>
                      <li>Donr provides Text Giving and other fundraising services to charities who are registered in England, Wales, Scotland and Northern Ireland.</li>
                      <li>Donr is a private, UK-registered company (company number: 10402410).</li>
                    </ul>
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import { ExternalLinkIcon } from '@heroicons/vue/solid'

const donateToSmsNumber = '70085'
const donateKeyword = 'APPBLR'

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    ExternalLinkIcon
  },
  setup() {
    const donationAmounts = [5, 10, 20]
    const selectedDonationAmount = ref(null)

    return {
      donationAmounts,
      selectedDonationAmount,
      donateToSmsNumber,
      donateKeyword
    }
  },
}
</script>